<template>
	<ModalTemplate v-model="isShow" 
	name="slot-color-picker"
	queryName="m_scp"
	:closer="() => isShow = false">
		<div class="color-picker">
			<div class="slot" v-bind:style="styles">
				{{slotTitle || "Slot title"}}
			</div>
			<div class="color-panel">
				<color-panel v-model="newColors.mainColor" @change="updateTextColor">
				</color-panel>
			</div>
			<div class="submit_buttons_cont">
				<b-button @click="setColor" type="is-info">
					Choose
				</b-button>
				<b-button @click="closeModal" type="is-info is-light">
					Cancel
				</b-button>
			</div>
		</div>
	</ModalTemplate>
</template>

<script>
import ModalTemplate from '../ModalTemplate.vue';
import { Button } from 'buefy'
import { hexToRgb } from '../../../scripts/color';
import ModalProvider from '../../../scripts/modalProvider';

export default {
	name: 'SelectSlotColorModal',
	components: {
		ModalTemplate,
		Button,
	},
	emits: ['on-change-color'],
	model:{
		prop:'show',
		event: 'update:show'
	},
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		color: {
            type: Object,
            required: true,
        },
        slotTitle: {
            type: String,
            default: "Slot text"
        },
	},
	computed: {
		isShow: {
			get(){
				return this.show
			},
			set(value){
				this.$emit('update:show', value)
			}
		},
		styles(){
			return {
				'background': this.newColors.mainColor,
				'color': this.newColors.textColor
			}
		}
	},

	data() {
		return {
			newColors: {
                mainColor: this.color.mainColor,
                textColor: this.color.textColor,
            }
		};
	},

	watch: {
        color(newValue){
            this.newColors = newValue
        }
    },

	methods: {
		closeModal(){
			ModalProvider.back()
		},
		updateTextColor() {
            let rgbColor = hexToRgb(this.newColors.mainColor)

            let red = rgbColor[0]
            let green = rgbColor[1]
            let blue = rgbColor[2]

            if (1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255 < 0.5){
                this.newColors.textColor = "#000000"
            } else {
                this.newColors.textColor = "#ffffff"
            }
        },
        setColor(){
            this.$emit('on-change-color', {
                mainColor: this.newColors.mainColor,
                textColor: this.newColors.textColor,
            })
            this.closeModal()
        },
	},
};
</script>

<style>

.one-colorpanel.one-hsv{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.one-colorpanel.one-hsv > .one-colors{
	height: 100%;
	width: 100%;
	max-height: 320px
}
</style>

<style scoped>
.modal__window{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-picker{
	position: absolute;
	top: 40px;
	bottom: 70px;
	left: 20px;
	right: 20px;
    background: white;
    border-radius: calc(0.25em + 8px);
    padding: 16px;

    display:flex;
	flex-direction: column;
	gap: 16px;
}

.color-panel {
	display: flex;
	justify-content: center;
	height: 100%;
}

.submit_buttons_cont{
    padding: 20px;
    padding-top: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    background: white;
    height: 40px;
	padding: 0;
}

.save__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    background: #0075ff;
    border-radius: 7px;
    color: white;

    font-size: 20px;
    font-weight: bold;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}

.cancel__button{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    height: 40px;
    color: #0075ff;
    text-decoration: underline;
}

.slot{
    height: 36px;
    border: 1px solid #a7a7a7;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}
</style>