<template>
    <div class="slot-color-picker">
        <div class="slot" @click="show = true" v-bind:style="styles">
            Text
        </div>
		<SelectSlotColorModal
			v-model="show"
			:color="changedColor"
			@on-change-color="changedColor = $event"
			:slotTitle="slotTitle"
		/>
    </div>
</template>

<script>
import SelectSlotColorModal from '../modals/select-slot-color/SelectSlotColorModal.vue';

export default {
    name: 'SlotColorPicker',
    components: {
		SelectSlotColorModal
    },
    model:{
        prop:'color',
        event: 'update:color'
    },  
    props: {
        color: {
            type: Object,
            required: true,
        },
        slotTitle: {
            type: String,
            default: "Slot text"
        },
    },
	computed: {
		changedColor: {
			get(){
				return this.color
			},
			set(value){
				this.$emit('update:color', value)
			}
		},
		styles(){
			return {
				'background': this.color.mainColor,
				'color': this.color.textColor
			}
		}
	},
    data() {
        return {
            show: false,
        };
    },
};
</script>

<style scoped>
.slot-color-picker{
    width: 106px;
}
.slot{
    height: 36px;
    border: 1px solid #a7a7a7;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
}


</style>